import React from "react";
import Layout from "../../../components/layout"
import SearchResultPage from "../../../templates/search-results-template";

const SearchResultResiSales = (props) => {

    const fullpath = props.location.pathname
    const location = props.location
    const fullpathone = props.location
    const [scroll, setScroll] = React.useState(false);

    React.useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 60)
        })
    }, [])

    return (
        <div className='search-results-page'>
            <Layout>
                <section className={scroll ? "search-banner search-banner--fixed" : "search-banner"}>
                    <SearchResultPage
                        locationname="/off-plan-property/for-sale/"
                        offplanproperty={true}
                        pcategorytype="residential"
                        multipcategorytype={['residential']}
                        ptype="sales"
                        ptypetag="for-sale"
                        completionstatus="Off Plan"
                        pstatustype={['for-sale', 'sold-stc', 'sold', 'under-offer']}
                        fullpathone={fullpathone}
                        fullpathname={fullpath}
                        {...props}
                        psearch="Sales"
                    />
                </section>
            </Layout>
        </div>
    );
};

export default SearchResultResiSales;